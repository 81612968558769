import React from 'react'
import BlogList from '../components/BlogList'
import BlogData from '../Data/BlogData'
import '../components/main.css'

const Blog = () => {
    return (
        <div className="main-blog">
            {BlogData.map((blog, index) => {
                return(
                    <BlogList blog={blog} key={index}/>
                )
            })}
        </div>
    )
}

export default Blog
