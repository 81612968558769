import React from 'react'
import Chart from './Chart'
import ChartList from '../Data/ChartList'

const QuotesList = () => {
    return (
        <>
            {ChartList.map((item, index) => {
                return(
                    <div key={index} className="quoteList">
                        <Chart number={item.number} unit={item.unit} divide={item.divide} text={item.text}/>
                        <div className="quoteList-p">
                            <p>{item.name}</p>
                        </div>
                    </div>
                )
            })}   
        </>
    )
}

export default QuotesList
