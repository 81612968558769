import React from 'react'
import { NavLink } from 'react-router-dom'
import Video from './Presentatiton_3.mp4'
import './main.css'

const Banner = () => {
    return (
        <div className="banner">
            <div className="video-container">
                <video src={Video} autoPlay muted loop width="100%" height="100%"/>
            </div>
            <div className="banner-content">
                {/* <div className="div1"><h1>We care for our clients</h1></div>
                <br /> 
                <div className="div2"> 
                <span>One step solution at your fingertips</span>
                </div> */}
                <div className="text-flicker-in-glow">
                    <h1>Global Cyber Aid For Individuals & Organization</h1>
                </div>
                <br/><br/>
                <NavLink to="/connect" className="btn">Let's Connect</NavLink>
            </div>
        </div>
    )
}

export default Banner
