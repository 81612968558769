import React from 'react'
import './main.css'

const NotFound = () => {
    return (
        <div className="shake-horizontal">
            <h3>This is not a page</h3>
        </div>
    )
}

export default NotFound
