import React, { useState } from 'react'
import Navbar from './components/Navbar';
import Home from './MainScreen/Home';
import Connect from './components/Connect';
import AboutUs from './Screens/AboutUs';
import Blog from './Screens/Blog';
import OurServices from './Screens/OurServices';
import Footer from './components/Footer';
import NotFound from './components/NotFound';
import { Switch, Route } from 'react-router-dom'
import './App.css';

const App = () => {

  const [loadSite, setLoadSite] = useState(true)

  const timer = setTimeout(() => {
    setLoadSite(false)
    clearTimeout(timer)
  }, 700)

  return (
    <>
    {loadSite ? 
    <div style={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#f0efeb'}}>
        <img className="loaderLogo" src={process.env.PUBLIC_URL + '/images/loaderLogo.png'} alt="initial"/>
        <h2 style={{fontFamily: 'Raleway, sans-sarif'}}>Welcome to Cyber Aid</h2>
    </div> 
    :
    <div className="App">
      <Navbar/>
        <main>
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route exact path="/company-info" component={AboutUs}/>
            <Route exact path="/services" component={OurServices}/>
            <Route exact path="/blog" component={Blog}/>
            <Route exact path="/connect" component={Connect}/>
            <Route component={NotFound}/>
          </Switch>       
        </main>
      <Footer/>
    </div>  
    }
    </>
  );
}

export default App;
