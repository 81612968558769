import React from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './main.css'

const Chart = ({number, unit, divide, text}) => {

    return (
      <>
          <div className="chart">
            <CircularProgressbar
              value={number}
              text="0%"
              styles={buildStyles({
                rotation: 0.25,
                strokeLinecap: 'butt',
                textSize: '16px',
                pathTransitionDuration: 0.5,
                pathColor: `rgba(225, 0, 0, 0)`,
                textColor: '#ffff',
                trailColor: '#fff',
                backgroundColor: '#ffffff',
              })}
            />
        </div>
      </>
    )
}

export default Chart
