import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import './main.css'

const Footer = () => {

    const year = new Date()

    return (
        <div style={{display: 'flex', flexDirection: 'column-reverse'}}>
            <div className="footer">
                <div><h4>Copyright &copy; {year.getFullYear()} Cyber Aid</h4></div>
                <div><h4>Developed by <span><a href="https://aveneur.com/#/" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: '#177e70', cursor: 'pointer'}}>Aveneur</a></span></h4></div>
            </div>
            <hr />
            <div className="footer">
                <FontAwesomeIcon icon={faFacebookF} size="2x" className="social fb"/>
                <FontAwesomeIcon icon={faInstagram} size="2x" className="social ig"/>
                <FontAwesomeIcon icon={faLinkedinIn} size="2x" className="social li"/>
                <FontAwesomeIcon icon={faTwitter} size="2x" className="social tw"/>
            </div>
        </div>
    )
}

export default Footer
