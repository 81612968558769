import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import '../components/main.css'

const ContactModal = ({showModal3, setShowModal3}) => {

    return (
        <div className={`${showModal3 ? 'contactModal activeContact' : 'contactModal'}`}>
             <div className="formClosed">
                <FontAwesomeIcon icon={faTimes} onClick={() => setShowModal3(false)} className="formTimes"/>
            </div>
            <h1 style={{padding: '1rem'}}>Get In Touch With Us!</h1>
            <div className="tel">
                <h3>You Can Contact Us Through Our Available Numbers</h3>
                <div style={{display: 'flex', justifyContent: 'center'}} className="numbers">
                    <a style={{padding: '1rem'}} href="tel:+880-175-6389-293">+8801756389293</a>
                    <a style={{padding: '1rem'}} href="tel:+880-175-6389-293">+8801756389293</a>
                    <a style={{padding: '1rem'}} href="tel:+880-175-6389-293">+8801756389293</a>
                </div>
            </div>
            <br />
            <h1>OR</h1>
            <br />
            <div>
                <h3>Contact Us via Email</h3>
                <a style={{padding: '1rem'}} href = "mailto: abc@example.com">Open mail</a>
            </div>
        </div>
    )
}

export default ContactModal
