import React, { useState } from 'react'
import FormModal from '../Screens/FormModal'
import InvestigationModal from '../Screens/InvestigationModal'
import ContactModal from '../Screens/ContactModal'
import './main.css'

const Services = () => {

    const [showModal, setShowModal] = useState(false)
    const [showModal2, setShowModal2] = useState(false)
    const [showModal3, setShowModal3] = useState(false)

    const openModel = () => setShowModal(!showModal)
    const openModel2 = () => setShowModal2(!showModal2)
    const openModel3 = () => setShowModal3(!showModal3)

    return (
        <>
        <div className="card card1">
            <img className="smol1" src={process.env.PUBLIC_URL + '/images/contact.png'} alt="contact" onClick={openModel3}/>
        </div>
        <div className="card card2">
            <img className="smol2" src={process.env.PUBLIC_URL + '/images/investigate.png'} alt="investigation" onClick={openModel2}/>
        </div>
        <div className="card card3">
            <img className="smol3" src={process.env.PUBLIC_URL + '/images/report.png'} alt="report" onClick={openModel}/>
        </div>
        <FormModal showModal={showModal} setShowModal={setShowModal}/>
        <InvestigationModal showModal2={showModal2} setShowModal2={setShowModal2}/>
        <ContactModal showModal3={showModal3} setShowModal3={setShowModal3}/>
        </>
    )
}

export default Services
