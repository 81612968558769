import React from 'react'
import Banner from '../components/Banner';
import NewsSlide from '../components/NewsSlide'
import ServicesView from '../Screens/ServicesView';
import Quotes from '../Screens/Quotes';

const Home = () => {
    return (
        <>
          <Banner/>
          <ServicesView/>
          <Quotes/>
          <NewsSlide/>   
        </>
    )
}

export default Home
