import React from 'react'
import ServiceCard from '../components/ServiceCard'

const OurServices = () => {
    return (
        <div className="our-service">
            <ServiceCard/>
        </div>
    )
}

export default OurServices
