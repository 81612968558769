import React from 'react'

const BlogList = ({blog}) => {
    const randomColor = Math.floor(100000 + Math.random() * 900000)

    return (
        <div className="blog" style={{background: `#${randomColor}`}}>
            <img src={blog.image} alt="blogImage" />
            <div className="blog-body">
                <h5>{blog.title}</h5>
            </div>
            <div className="blog-footer">
                <a href={blog.link} target="_blank" rel="noopener noreferrer">
                    Read More
                </a>
            </div>
        </div>
    )
}

export default BlogList
