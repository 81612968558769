import React from 'react'
import members from '../Data/MembersData'
import '../components/main.css'

const AboutUs = () => {
    return (
        <div className="aboutUs">
            {/* <div className="company-card">
                <div className="card-header">
                    <img src={process.env.PUBLIC_URL + '/images/kirk.png'} alt=""/>
                </div>
                <div className="card-body">
                    <p>“We believe in the aims and objectives of GCHQ and the National Cyber Security Centre, to that end we promote the governments Cyber programmes. Our goal is to steer UK business towards achieving the minimum standard of Cyber Security protection.  We continue to provide an extremely high quality service to those personalities across the globe that require extra elements of security to manage and maintain their privacy”</p>
                    <div className="card-foot" style={{fontSize: '1rem', padding: '1rem 5px', color: 'crimson'}}>
                    <p>
                        Anthony Upward EWCM – CIO of Cognition Intelligence
                    </p>
                </div>
                </div>
            </div> */}
            <div className="consultancy" style={{background: '#12161f'}}>
                <div style={{textAlign: 'center'}}>
                    <img src={process.env.PUBLIC_URL + '/images/logo.png'} width="200px" alt=""/>
                    <h3 style={{textAlign: 'justify'}}>
                        Cyber Aid is a cyber-security consultancy that supports, protects and guides the interests of individuals by providing a safe space for cyber-crime victims through a confidential helpline. Simultaneously, Cyber Aid is also known to provide cyber security solutions for businesses and professional industry bodies in an initiative to promote excellence across the technical surveillance and cyber security sector.
                    </h3>
                </div>
                <div className="description">
                    <p>
                        Cyber Aid was created with the idea that the taboo surrounding cyber-crime should not be allowed to prevail in the 21st century when the world has advanced and made progresses beyond boundaries of the Earth. It should be tackled and dealt with like any other crime. The primary goal is to gather industry experts in an attempt to encourage victims to come forward and take steps to raise awareness in the society and try to eradicate the existence of cyber-crime and cyber criminals. 
                        Just like individuals suffering from cyber-crime and its accompanying trauma, businesses often lose years of their work and effort due to a lack of awareness of the requirement of a good cyber security solution. That is where Cyber Aid comes in with innovative technological approaches to secure each business according to their custom needs. 
                        Cyber Aid’s mission is a testament of their core values. They are a community of technology and business consultants, all committed to delivering quality, cost-effective, impactful solutions that create long term value and our clients’ mission.
                    </p>
                </div>
            </div>
            <div className="members">
                <h1>Our Members</h1>
                <div className="members-div">
                    {members.map((member, index) => {
                        return(
                            <div className="member" key={index} style={{background: '#12161f', padding: '5px'}}>
                                <img src={member.image} alt="" width="200px"/>
                                <br /><br />
                                <div className="member-body">
                                    <p>{member.title}</p>
                                    <br /><br />
                                    <p style={{textAlign: 'justify', padding: '5px'}}>{member.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>    
            </div>  
        </div>
    )
}

export default AboutUs
