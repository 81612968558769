import React from 'react'
import QuotesList from '../components/QuotesList'
import '../components/main.css'

const Quotes = () => {
    return (
        <div className="quotes">
            <QuotesList/>
        </div>
    )
}

export default Quotes
