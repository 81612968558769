import React from 'react'
import './main.css'

const Form = () => {

    return (
        <div className="jotForm">
            <iframe
            id="JotFormIFrame-212503340652444"
            title="Report Form"
            allow="geolocation; microphone; camera"
            src="https://form.jotform.com/212503340652444"
            frameBorder="0"
            style={{minWidth: '100%', height: '80vh', border: 'none', scrolling: 'none'}}
            />
        </div>
    )
}

export default Form
