const members = [
    {
        image: '/images/membersIcon/aveneur.png',
        title: 'Aveneur Solutions',
        description: 'Aveneur Solutions is an aesthetic-first web design, digital content and software development company - serving businesses both large and small. They promote innovation, develop ideas into reality and provide opportunities for businesses to grow.'
    },
    {
        image: '/images/membersIcon/legalx.png',
        title: 'Legal X',
        description: "LegalX is an initiative to digitize the legal sector of the country by keeping up with the current era of Digital Bangladesh by bringing legal access at peoples' fingertips. Apart from that, they help people find their desired lawyers in Bangladesh."
    },
    {
        image: '/images/membersIcon/backdoor.png',
        title: 'Backdoor',
        description: 'Backdoor Private Ltd. secures the largest enterprise, service provider, and government organizations around Bangladesh. backdoor empowers its customers with intelligent, seamless protection across the expanding attack surface and the power to take on ever-increasing performance requirements of the border-less network.'
    },
    // {
    //     image: 'https://i0.wp.com/cognitionint.com/wp-content/uploads/2018/03/iisp-logo.png?resize=100%2C99&ssl=1',
    //     title: 'The Institute of Information Security Professionals',
    //     description: 'The Institute of Information Security Professionals The IISP accredit information security professionals to ensure that they meet the highest professional standards. Unlike most other certifications available today, the IISP does not accredit on learned knowledge alone. Instead they require professionals to evidence that they have successfully performed the required skills in the real world and have a track record of delivering to the highest standards. Full Membership of the IISP is the Gold standard for information security professionals.'
    // },
    // {
    //     image: 'https://i2.wp.com/cognitionint.com/wp-content/uploads/2018/03/ceop-logo.png?resize=100%2C99&ssl=1',
    //     title: 'CEOP Ambassadors',
    //     description: 'CEOP is a National Crime Agency Command. It exists to prevent the exploitation of children & young people. For us at Cognition Intelligence it is so important to protect our young from the continual threats we find, that is why our CIO became a CEOP Ambassador. CEOP Ambassadors focus on the protection of children and young people from online sexual abuse and exploitation. Our role as a CEOP Ambassador is to play the important role in cascading the programme to other professionals and parents. If you wish to discuss us providing you or your school with any free support or advice please contact us.'
    // }
]

export default members