const blogList = [
    {
        image: 'https://abovethelaw.com/uploads/2015/09/video-game-300x200.jpg',
        title: 'Chinese Government Limits Access To Online Video Games To 3 Hours Per Week For Children And Teenagers',
        link: 'https://abovethelaw.com/2021/09/chinese-government-limits-access-to-online-video-games-to-3-hours-per-week-for-children-and-teenagers/'
    },
    {
        image: 'https://info.abovethelaw.com/hs-fs/hubfs/litera_webinar_image.png?width=314&name=litera_webinar_image.png',
        title: "TRANSACTION MANAGEMENT FALLS SHORT. HERE'S HOW YOURS CAN BE BETTER.",
        link: 'https://info.abovethelaw.com/transaction-management-falls-short.-heres-how-yours-can-be-better'
    },
    {
        image: 'https://medcitynews.com/uploads/2021/08/GettyImages-1136139496-600x400.jpg',
        title: 'Sutter Health to pay $90M to resolve False Claims allegations',
        link: 'https://medcitynews.com/2021/08/sutter-health-to-pay-90m-to-resolve-false-claims-allegations/'
    },
    {
        image: 'https://abovethelaw.com/uploads/2021/08/chess-2730034_1920-300x169.jpg',
        title: 'How To Use Legal Analytics To Craft The Best Litigation Strategy ',
        link: 'https://abovethelaw.com/2021/09/how-to-use-legal-analytics-to-craft-the-best-litigation-strategy/'
    },
    {
        image: 'https://abovethelaw.com/uploads/2017/07/cloud-computing-big-data-analytics-servers-metrics.jpg',
        title: 'In-House Counsel Want Legal Spend Analytics... New Merger Aims To Give It To Them Even Easier',
        link: 'https://abovethelaw.com/2021/09/in-house-counsel-want-legal-spend-analytics-new-merger-aims-to-give-it-to-them-even-easier/'
    },
    {
        image: 'https://abovethelaw.com/uploads/2017/03/woman-lawyer-female-attorney-running-ahead-of-men.jpg',
        title: 'Turning A Lagging Law Firm Into A Tech Leader',
        link: 'https://abovethelaw.com/2021/08/turning-a-lagging-law-firm-into-a-tech-leader/'
    },
    {
        image: 'https://abovethelaw.com/uploads/2018/01/grave-rose-300x207.jpg',
        title: '93-Year-Old Skadden Lawyer With Ties To Firm’s Name Partners Was Still Working On Cases When He Passed Away',
        link: 'https://abovethelaw.com/2021/09/93-year-old-skadden-lawyer-with-ties-to-firms-name-partners-was-still-working-on-cases-when-he-passed-away/'
    },
    {
        image: 'https://abovethelaw.com/uploads/2016/09/gavel-apple-law-school-books-300x200.jpg',
        title: 'The Law Schools Whose Professors Have The Most Scholarly Impact (2021)',
        link: 'https://abovethelaw.com/2021/09/the-law-schools-whose-professors-have-the-most-scholarly-impact-2021/'
    },
    {
        image: 'https://abovethelaw.com/uploads/2017/07/Supreme-Court-sunset-SCOTUS-dusk-red-orange-sky-300x225.jpg',
        title: 'The Missed Opportunities Haunting The Supreme Court’s Abortion Move',
        link: 'https://abovethelaw.com/2021/09/the-missed-opportunities-haunting-the-supreme-courts-abortion-move/'
    },
    {
        image: 'https://abovethelaw.com/uploads/sites/3/2021/08/ian-hutchinson-U8WfiRpsQ7Y-unsplash-300x169.jpeg',
        title: 'Free August Course: SCOTUS Review 2021 Part I: Criminal Law',
        link: 'https://abovethelaw.com/lawline-cle/2021/08/01/free-august-course-scotus-review-2021-part-i-criminal-law/'
    },
    {
        image: 'https://abovethelaw.com/uploads/2020/02/washington-monument-1628558_640-300x154.jpg',
        title: 'Biglaw Firm Expands With New D.C. Office, Focusing On Antitrust And Trade',
        link: 'https://abovethelaw.com/2021/09/biglaw-firm-expands-with-new-d-c-office-focusing-on-antitrust-and-trade/'
    },
    {
        image: 'https://abovethelaw.com/uploads/2016/06/fertility-clinic-IVF-in-vitro-fertilization-test-tube-baby-babies-300x200.jpg',
        title: 'DNA Test Reveals Unexplainable Fertility Clinic Mix-Up; Families Seek Damages',
        link: 'https://abovethelaw.com/2021/09/dna-test-reveals-unexplainable-fertility-clinic-mix-up-families-seek-damages/'
    }
]

export default blogList