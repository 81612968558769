const chart = [
    {
        name: 'new malware samples are captured every business quarter on average',
        text: 18,
        number: 87,
        unit: 'Million',
        divide: 100
    },
    {
        name: 'ransomware attacks happen every day since 2016',
        text: 4000,
        number: 80,
        unit: '',
        divide: 100
    },
    {
        name: 'of phishing emails contain a form of ransomware on average',
        text: 97,
        number: 97,
        unit: '%',
        divide: 100
    },
    {
        name: 'are aware of the risks of unknown links in emails, and yet they click anyway',
        text: 78,
        number: 79,
        unit: '%',
        divide: 100
    },
    {
        name: 'the amount of time it takes on average to detect a cyber attack',
        text: 150,
        number: 85,
        unit: 'days',
        divide: 100
    }
]

export default chart