import React from 'react'
import Particles from './Particles'
import './main.css'

const Connect = () => {
    return (
        <div className="connect">
            <Particles/>
            <div>
                <p>Dhaka Office: 326 West Palashbagh, Rampura, 1219, Dhaka</p>
                <p>Chittagong Office: Baitul Aman Market,934 O R Nizam Road, Chittagong,Bangladesh</p>
                <p>Singapore Office: 160 Robinson Road, #14-04 Singapore Business Federation Centre</p>
                <p>Corporate Number: +8801500768</p>
            </div>
            <div className="contactForm">
                <iframe
                id="JotFormIFrame-2125039112654486"
                title="Connect Form"
                allow="geolocation; microphone; camera"
                src="https://form.jotform.com/212503911265448"
                frameBorder="0"
                style={{minWidth: '100%', height: '585px', border: 'none'}}
                />
            </div>
        </div>
    )
}

export default Connect
