const train = [
    {
        image: '/images/training/1.jpeg'
    },
    {
        image: '/images/training/2.jpeg'
    },
    {
        image: '/images/training/3.jpeg'
    },
    {
        image: '/images/training/4.jpeg'
    },
    {
        image: '/images/training/5.jpeg'
    },
    {
        image: '/images/training/6.jpeg'
    },
    {
        image: '/images/training/7.jpeg'
    },
    {
        image: '/images/training/8.jpeg'
    },
    {
        image: '/images/training/9.jpeg'
    },
    {
        image: '/images/training/10.jpeg'
    },
    {
        image: '/images/training/11.jpeg'
    },
    {
        image: '/images/training/12.jpeg'
    },
    {
        image: '/images/training/13.jpeg'
    },
    {
        image: '/images/training/14.jpeg'
    },
    {
        image: '/images/training/15.jpeg'
    },
    {
        image: '/images/training/16.jpeg'
    },
    {
        image: '/images/training/17.jpeg'
    },
    {
        image: '/images/training/18.jpeg'
    },
]

export default train