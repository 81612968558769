import React from 'react'
import './main.css'

const ServiceCard = () => {
    return (
        <>
            <div className="service-container">
                <div className="service-heading">
                    <><img src={process.env.PUBLIC_URL + '/images/serviceIcons/identity.png'} alt="avatar" width="40px"/></>
                </div>
                    <div className="service-card">
                        <div className="service-header">
                            <h1>Identity</h1>
                        </div>
                        <div className="service-body">
                            <p>
                                Gain full visibility into your digital and physical assets and understand your current risks and exposure
                            </p>
                        </div>
                        <div className="service-button">
                            <button>Cyber TSCM</button>
                            <button>Cyber Security</button>
                            <button>Managed Vulnerability</button>
                            <button>Phising Insight</button>
                            <button>Penetration Testing</button>
                            <button>Cyber Essentials</button>
                        </div>
                    </div>
            </div>

            <div className="service-container">
                <div className="service-heading">
                    <><img src={process.env.PUBLIC_URL + '/images/serviceIcons/detect.png'} alt="avatar" width="40px"/></>
                </div>
                    <div className="service-card">
                        <div className="service-header">
                            <h1>Detect</h1>
                        </div>
                        <div className="service-body">
                            <p>
                                Threat Intelligence is  a top priority for organisations as boardrooms aim to take control of their cyber risk.
                            </p>
                        </div>
                        <div className="service-button">
                            <button>Managed Cyber</button>
                            <button>Overwatch IDS</button>
                            <button>TSCM Prevention</button>
                            <button>Wireless Surveys</button>
                        </div>
                    </div>
            </div>

            <div className="service-container">
                <div className="service-heading">
                    <><img src={process.env.PUBLIC_URL + '/images/serviceIcons/protect.png'} alt="avatar" width="40px"/></>
                </div>
                    <div className="service-card">
                        <div className="service-header">
                            <h1>Protect</h1>
                        </div>
                        <div className="service-body">
                            <p>
                                Websites and applications require the resilience and intelligence of a scalable network to combat the biggest and newest attacks.
                            </p>
                        </div>
                        <div className="service-button">
                            <button>Firewall Architects</button>
                            <button>Intrusion Detection</button>
                            <button>DDos Protection</button>
                            <button>High Net Worth</button>
                        </div>
                    </div>
            </div>

            <div className="service-container">
                <div className="service-heading">
                    <><img src={process.env.PUBLIC_URL + '/images/serviceIcons/respond.png'} alt="avatar" width="40px"/></>
                </div>
                    <div className="service-card">
                        <div className="service-header">
                            <h1>Respond</h1>
                        </div>
                        <div className="service-body">
                            <p>
                                With a critical situation in progress that is already affecting your business, we are on hand to respond and manage the incident and protect your business.
                            </p>
                        </div>
                        <div className="service-button">
                            <button>Incident Response</button>
                            <button>TSCM Incident Management</button>
                        </div>
                    </div>
            </div>

            <div className="service-container">
                <div className="service-heading">
                    <><img src={process.env.PUBLIC_URL + '/images/serviceIcons/recover.png'} alt="avatar" width="40px"/></>
                </div>
                    <div className="service-card">
                        <div className="service-header">
                            <h1>Recover</h1>
                        </div>
                        <div className="service-body">
                            <p>
                                Every company that relies on IT to run their business is vulnerable to disaster. It requires planning to recover from disaster effectively and rapidly.
                            </p>
                        </div>
                        <div className="service-button">
                            <button>Recovery Planning</button>
                            <button>Improvement Analysis</button>
                            <button>Secure Communication</button>
                        </div>
                    </div>
            </div>
        </>
    )
}

export default ServiceCard
