import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import './main.css'

const Navbar = () => {

    const [toggle, setToggle] = useState(false)
    const enableScroll = () => document.body.style.overflow = "initial"
    const disableScroll = () => document.body.style.overflow = "hidden"

    const handleToggle = () => setToggle(!toggle)
    
    toggle ? disableScroll() : enableScroll()

    return (
        <header className="header">

            {/* <nav className="nav">
                <ul>
                    <li><NavLink exact activeClassName="active-nav" to="/">Home</NavLink></li>
                    <li><NavLink activeClassName="active-nav" to="/about-us">AboutUS</NavLink></li>
                    <li><NavLink activeClassName="active-nav" to="/services">Services</NavLink></li>
                    <li><NavLink activeClassName="active-nav" to="/blog">Blog</NavLink></li>
                </ul>
            </nav> */}
            <NavLink to="/" className="logo" onClick={() => setToggle(false)}><img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="logo"/></NavLink>

            <div className="toggle">
                <FontAwesomeIcon icon={toggle ? faTimes : faBars} size="2x" onClick={handleToggle} className="toggle-button"/>
            </div>
            {/* <NavLink to="/connect" className="btn">Let's Connect</NavLink> */}
            {/* <div className="socials">
                <FontAwesomeIcon icon={faFacebookF} size="2x" className="social fb"/>
                <FontAwesomeIcon icon={faInstagram} size="2x" className="social ig"/>
                <FontAwesomeIcon icon={faLinkedinIn} size="2x" className="social li"/>
                <FontAwesomeIcon icon={faTwitter} size="2x" className="social tw"/>
            </div> */}
            
            <div className={`${toggle ? 'nav-dropdown active' : 'nav-dropdown'}`}>
                <ul>
                    <li onClick={() => setToggle(false)}><NavLink exact activeClassName="active-nav" to="/">Home</NavLink></li>
                    <li onClick={() => setToggle(false)}><NavLink activeClassName="active-nav" to="/company-info">Company Info</NavLink></li>
                    <li onClick={() => setToggle(false)}><NavLink activeClassName="active-nav" to="/services">Services</NavLink></li>
                    <li onClick={() => setToggle(false)}><NavLink activeClassName="active-nav" to="/blog">All Blogs</NavLink></li>
                    {/* <NavLink to="/connect" onClick={() => setToggle(false)} className="btn-dropdown">Let's Connect</NavLink> */}
                    {/* <div className="links">
                        <FontAwesomeIcon icon={faFacebookF} size="2x" className="social fb"/>
                        <FontAwesomeIcon icon={faInstagram} size="2x" className="social ig"/>
                        <FontAwesomeIcon icon={faLinkedinIn} size="2x" className="social li"/>
                        <FontAwesomeIcon icon={faTwitter} size="2x" className="social tw"/>
                    </div> */}
                </ul>
            </div>
        </header>
    )
}

export default Navbar
