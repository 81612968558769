import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import InvestigationForm from '../components/InvestigationForm'
import '../components/main.css'

const InvestigationModal = ({showModal2, setShowModal2}) => {

    return (
        <>
        <div className={`${showModal2 ? 'form activeForm' : 'form'}`}>
            <div className="formClosed">
                <FontAwesomeIcon icon={faTimes} onClick={() => setShowModal2(false)} className="formTimes"/>
            </div>
            <InvestigationForm/>
        </div>
        </>
    )
}

export default InvestigationModal
